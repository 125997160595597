import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  AuthProvider,
  // AuthProtectedRoute
} from './components/Auth';

import {
  Auth0Provider,
  useAuth0
} from '@auth0/auth0-react';

import { Box } from '@mui/material'

import Header from './components/Navbar'
import Footer from './components/Footer'
import NoPage from './pages/NoPage'

import Home from './pages/Home'
import Inquiry from './pages/Contact'

import Profile from './pages/User/Profile'

// import reportWebVitals from './reportWebVitals';


const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID

type PageComponentsType = {
  [key: string]: () => JSX.Element;
};

const PageComponents: PageComponentsType =  {
  '/user/profile': Profile
  // 1st page : 1st page jsx element,
  // 2nd page : 2nd page element
}

const pages = Object.keys(PageComponents);

const createSettings = (logout: () => void) => [
  {
    title: 'Account',
    onClick: () => console.log('Account')
  },
  {
    title: 'Dashboard',
    onClick: () => console.log('Dashboard')
  },
  {
    title: 'Logout',
    onClick: () => {
      console.log('logout called')
      localStorage.removeItem('NRBA_SESSION')
      logout()
    }
  }
]

function App() {
  const { logout } = useAuth0();
  const settings = useMemo(() => createSettings(logout), [logout]);

  return (
    <div className="App" style={{backgroundColor: '#f5f5f5'}}>
      <BrowserRouter>
        <Header settings={settings}/>
        <Routes>
          <Route path="/" element={
            <Box>
              <Home />
            </Box>
          } />
          <Route path="/contact" element={
            <Box>
              <Inquiry />
            </Box>
          } />
          <Route path="*" element={
            <Box>
              <NoPage />
            </Box>
          } />
          {/* <Route path="*" element={<NoPage />} /> */}
          {pages.map((page) => {
            const PageComponent = PageComponents[page]
              return (
                <Route
                  key={page}
                  path={page}
                  element={
                    <PageComponent />
                  }
                  // element={
                  //   <AuthProtectedRoute element={
                  //     <ValidateAccess permission={`page:${page.toLowerCase()}`} />
                  //   } />
                  // }
                />
              )
            })};
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`${AUTH0_DOMAIN}`}
      clientId={`${AUTH0_CLIENT_ID}`}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://api.allmendelabs.com",
        scope: process.env.REACT_APP_AUTH0_SCOPE,
      }}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
