import React from 'react';
import { Box, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 1,
        py: 3,
        width: '100%',
        flexShrink: 0,
        alignItems: 'center',
        px: { xs: 2, md: 3 },
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Typography variant="caption" color="text.secondary">
        © 2024 Allmende llc. All rights reserved.
      </Typography>
    </Box>
  );
}