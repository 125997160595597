import React from 'react';
import { jsonToHtmlPrettyPrint } from '../utils/jsontoprettyprint';
import { Box, Alert } from "@mui/material";


interface AlertMessageProps {
  message: string | object;
  isErrorMessage: boolean;
}
const AlertMessageComponent: React.FC<AlertMessageProps> = ({ message, isErrorMessage }) => {
  if (message) {
    let content;
    if (typeof message === 'object') {
      const htmlContent = jsonToHtmlPrettyPrint(message);
      content = <span dangerouslySetInnerHTML={htmlContent}></span>;
    } else {
      content = message;
    }
    return (
      <Box sx={{margin : "1em"}}>
        <Alert severity={isErrorMessage ? 'error' : 'success'}>{content}</Alert>
      </Box>
    );
  }
  return null;
};

export default AlertMessageComponent;