import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AlertMessageComponent from "../../components/Alert";
import useEndpoint from '../../hooks/endpoint.hook';
import {
  Box,
  Typography,
  CircularProgress
} from "@mui/material";

const Profile = () => {
  const endpoint = useEndpoint();
  const {
    user,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  
  
  useEffect(() => {
    endpoint?.get(
      'https://api.allmendelabs.com/user/auth0/metadata'
    ).then((data) => {
        console.log(`userMetadata: ${JSON.stringify(data)}`);
      }).catch((err) => {
        console.error(err);
      })
  })


  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }

  if (!user) {
    return (
      <Box>
        <Typography variant="h2" sx={{ margin:'1em'}}>No User</Typography>
      </Box>
    )
  }

  if (!isAuthenticated) {
    return(
      <Box>
        <Typography variant="h2" sx={{ margin:'1em'}}>Not Authenticated</Typography>
      </Box>
    )
  }
  
  return (
    <Box>
      <Typography variant="h2" sx={{ margin:'1em'}}>Auth0 User Profile</Typography>
      <AlertMessageComponent message={user} isErrorMessage={false} />

      <Typography variant="h4" sx={{ margin:'1em'}}>Auth0 metadata:</Typography>
      {/* <AlertMessageComponent message={userMetadata} isErrorMessage={false} /> */}
      <Box>
        <Typography variant="h4" sx={{ margin:'1em'}}>Auth0 User Metadata Editor:</Typography>
        {/* <MetadataEditor metadata_type="user"/> */}
      </Box>
      <Box>
        <Typography variant="h4" sx={{ margin:'1em'}}>Auth0 App Metadata Editor:</Typography>
        <Typography variant="h6" sx={{ margin:'1em'}}>to use user/chat add openai_api_key:</Typography>
        {/* <MetadataEditor metadata_type="app"/> */}
      </Box>
    </Box>
  )
}

export default Profile