export function jsonToHtmlPrettyPrint(json: any) {
  // Convert JSON object to a string with indentation
  let jsonString = JSON.stringify(json, null, 2);

  // Convert special characters to HTML entities to safely insert the string into HTML
  let htmlString = jsonString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/\n/g, '<br>') // Newlines to <br>
    .replace(/ /g, '&nbsp;'); // Spaces to &nbsp;

  // Wrap in <pre> tag to preserve whitespace and formatting
  return { __html: `<pre>${htmlString}</pre>` };
}
