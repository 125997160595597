import { Typography, Box } from '@mui/material';

export default function NoPage() {
  return (
    <Box>
      <Typography variant="h1" align="center" gutterBottom
        sx={{
          fontWeight: 'bold',
          background: 'linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,212,255,1) 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
        404
      </Typography>

      <Typography variant="h6" align="center" gutterBottom
        sx={{
          fontWeight: 'bold',
          background: 'linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,212,255,1) 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
        Page Not Found
      </Typography>

      </Box>
  );
}