import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useForm, ValidationError } from "@formspree/react";
import {
  Button,
  Box,
  Typography,
  TextField,
  Grid2,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import WebhookIcon from '@mui/icons-material/Webhook';
import ApiIcon from '@mui/icons-material/Api';
import TerminalIcon from '@mui/icons-material/Terminal';

export default function ContactForm() {
  const [state, handleSubmit] = useForm("mldrggrn");

  if (state.succeeded) {
    return <Typography>Thanks for your submission!</Typography>;
  }

  const theme = createTheme({
    palette: {
      // mode: 'dark',
      mode: 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid2 container spacing={2} sx={{ p: 2 }}>
        <Grid2 sx={{ gridColumn: 'span 12', p: 2 }}>
          <Box>
            <Typography variant="h2" align="center" gutterBottom
              sx={{
                fontWeight: 'bold',
                background: 'linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,212,255,1) 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Get in Touch
            </Typography>
          </Box>
        </Grid2>

        <Grid2 sx={{ gridColumn: { xs: 'span 12', md: 'span 6', lg: 'span 7' }, p: 2 }}>
          <Box>
            <Grid2 container spacing={2} justifyContent="left" sx={{ pb: 4, pl: 7 }}>
              <Typography variant="h5">
                Reach out to see if we can help you meet your business needs
              </Typography>
            </Grid2>
            <Grid2 container spacing={2} justifyContent="left" sx={{ pl: 8 }}>
              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ApiIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Internal Data Insights" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WebhookIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="AI-Powered Client Outreach" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <TerminalIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Hand Tailored Business Automations" />
                </ListItem>
              </List>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 sx={{ gridColumn: { xs: 'span 12', md: 'span 6', lg: 'span 5' }, p: 2 }}>
          <Box>
            <Grid2 container spacing={2}
              sx={{
                m: 0,
                p: 2,
                borderRadius: 2,
                boxShadow: 3
              }}
            >
              <Grid2 sx={{ gridColumn: 'span 12' }}>
                <Typography variant="h4" align="center" gutterBottom>
                  Contact Us
                </Typography>
              </Grid2>
              <Grid2 sx={{ gridColumn: 'span 12' }}>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', minWidth: 160 }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }} />
                  <Grid2 container spacing={2}>
                    <Grid2 sx={{ gridColumn: 'span 12' }}>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email Address"
                        variant="outlined"
                        id="email"
                        name="email"
                        sx={{ mb: 2 }}
                      />
                      <ValidationError prefix="Email" field="email" errors={state.errors} />
                    </Grid2>
                    <Grid2 sx={{ gridColumn: 'span 12' }}>
                      <TextField
                        fullWidth
                        label="Your Company Name"
                        variant="outlined"
                        id="company"
                        name="company"
                        sx={{ mb: 2 }}
                      />
                      <ValidationError prefix="Company" field="company" errors={state.errors} />
                    </Grid2>
                    <Grid2 sx={{ gridColumn: 'span 12' }}>
                      <TextField
                        fullWidth
                        label="Job Title"
                        variant="outlined"
                        id="jobTitle"
                        name="jobTitle"
                        sx={{ mb: 2 }}
                      />
                      <ValidationError prefix="Job Title" field="jobTitle" errors={state.errors} />
                    </Grid2>
                    <Grid2 sx={{ gridColumn: 'span 12' }}>
                      <TextField
                        fullWidth
                        label="Message"
                        multiline
                        rows={4}
                        variant="outlined"
                        id="message"
                        name="message"
                        sx={{ mb: 2 }}
                      />
                      <ValidationError prefix="Message" field="message" errors={state.errors} />
                    </Grid2>
                    <Grid2 sx={{ gridColumn: 'span 12' }}>
                      <Button
                        type="submit"
                        disabled={state.submitting}
                        variant="contained"
                        sx={{
                          bgcolor: 'blue.400',
                          color: 'gray.900',
                          '&:hover': {
                            bgcolor: 'blue.500',
                          },
                          '&:focus': {
                            outline: 'none',
                            ring: 1,
                            ringColor: 'blue.300',
                          },
                          '&:disabled': {
                            pointerEvents: 'none',
                            opacity: 0.5,
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Grid2>
                  </Grid2>
                  <ValidationError errors={state.errors} />
                </Box>
              </Grid2>
            </Grid2>            
          </Box>
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
}