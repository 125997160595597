import { useState, useEffect } from 'react';
import { useAccessToken } from './access-token.hook';
import Endpoint from '../utils/endpoint.hook.util';

export function useEndpoint() {
  const accessToken = useAccessToken();
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);

  useEffect(() => {
    if (accessToken && endpoint === null) {
      setEndpoint(new Endpoint(accessToken));
    }
  }, [accessToken, endpoint]);

  return endpoint;
}

export default useEndpoint;
