import React from 'react';
import { Avatar, Typography, Grid, Box, Button } from "@mui/material";

const testimonials = [
  {
    title: "Marketing",
    heading: "Allmende AI has transformed our content creation process.",
    description: "We have been able to generate high-quality content at scale, saving our team countless hours.",
    initials: "SD",
    name: "Sofia Davis",
    role: "Marketing Manager, aLLMende Inc."
  },
  {
    title: "Operations",
    heading: "Allmende AI has streamlined our workflows and improved decision-making.",
    description: "The ability to automate repetitive tasks and generate insights from data has been a game-changer for our team.",
    initials: "JD",
    name: "John Doe",
    role: "Operations Manager, Allmende llc"
  },
];

interface TestimonialProps {
  title: string;
  heading: string;
  description: string;
  initials: string;
  name: string;
  role: string;
}

const Testimonial: React.FC<TestimonialProps> = ({ title, heading, description, initials, name, role }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: 2, p: { xs: 3, sm: 4 } }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'inline-block', bgcolor: 'grey.100', px: 1.5, py: 0.5, borderRadius: 1 }}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{heading}</Typography>
      <Typography variant="body2" color="text.secondary">{description}</Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Avatar>{initials}</Avatar>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>{name}</Typography>
        <Typography variant="body2" color="text.secondary">{role}</Typography>
      </Box>
    </Box>
  </Box>
);

export default function Home() {
  return (
    <Grid container spacing={2} sx={{ p: '1rem !important', m: '0 !important' }}>
      <Grid item xs={12} sx={{ pt: '10rem !important', pb: '10rem !important', px: '0 !important' }} id="about">
        <Box sx={{ display: 'grid', gap: { xs: 3, lg: 6 }, gridTemplateColumns: { lg: '1fr 400px', xl: '1fr 600px' }, px: 1, mx: 'auto', maxWidth: 1200 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h1" sx={{ fontSize: { xs: '2rem', sm: '3rem', xl: '3.75rem' }, fontWeight: 'bold', letterSpacing: '-0.02em' }}>
                Unlock the Power of Intuitive AI
              </Typography>
              <Typography variant="body1" sx={{ maxWidth: 600, color: 'text.secondary', fontSize: { md: '1.25rem' } }}>
                Allmende AI is a powerful LLM tool that empowers medium and enterprise businesses to automate
                workflows, generate content, and make data-driven decisions using the power of LLMs.
              </Typography>
            </Box>
          </Box>
          <Box component="img" src="/hero.png" alt="Hero" sx={{ mx: 'auto', borderRadius: 2, objectFit: 'cover', aspectRatio: '1/1', width: '100%', maxWidth: 550, height: 'auto' }} />
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ pt: '10rem !important', pb: '10rem !important', px: '0 !important' }} id="origin">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6, px: 1, mx: 'auto', maxWidth: 1200 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
            <Typography variant="h2" sx={{ fontSize: { xs: '2rem', sm: '3rem', xl: '3.75rem' }, fontWeight: 'bold', letterSpacing: '-0.02em' }}>
              Democratizing LLMs
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: { md: '1.25rem' } }}>
              Allmende Labs was born from the shared passion of two Bay Area engineers who dreamed of making tech accessible to everyone.
              Repurposing hardware from previous projects, we pieced together a small server rack and tucked it away in our shared apartment closet.
              Little did we know, this modest setup would become the initial springboard on our journey to democratize AI and bring the power of big data to everyone.
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ pt: '10rem !important', pb: '10rem !important', px: '0 !important' }} id="testimonials">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: { xs: 2, lg: 5 }, textAlign: 'center', px: { xs: 2, md: 3 }, mx: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Typography variant="h2" sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, fontWeight: 'bold', letterSpacing: '-0.02em' }}>
              What Our Customers Say
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: 700, mx: 'auto', color: 'text.secondary', fontSize: { md: '1.25rem' } }}>
              Hear from the businesses that have transformed their workflows with Allmende AI.
            </Typography>
          </Box>
          <Box sx={{ width: '100%', border: 1, borderColor: 'divider', borderRadius: 2, overflow: 'hidden' }}>
            <Grid container>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={6} key={testimonial.name} sx={{ borderBottom: { xs: index === 0 ? 1 : 0, md: 0 }, borderRight: { md: index === 0 ? 1 : 0 }, borderColor: 'divider' }}>
                  <Testimonial {...testimonial} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ pt: '12rem !important', pb: '10rem !important', px: '0 !important' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6, px: 1, mx: 'auto', maxWidth: 1200 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
            <Typography variant="h2" sx={{ fontSize: { xs: '2rem', md: '2.5rem' }, fontWeight: 'bold', letterSpacing: '-0.02em' }}>
              Experience the Power of Allmende AI
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: 600, color: 'text.secondary', mt: 2, fontSize: { md: '1.25rem' } }}>
              Currently our product is invite only, to apply for early access please fill out our contact form
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" href="/contact" sx={{ px: 4, py: 1, bgcolor: 'grey.900', color: 'grey.50', '&:hover': { bgcolor: 'grey.800' } }}>
                Contact
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}